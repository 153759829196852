import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import checked1 from '../images/checked.svg';
import group7 from '../images/group-7@3x.png';
import group8 from '../images/group-8@3x.jpg';
import star from '../images/images/star.webp';
import * as styles from '../styles/testimonial.module.css';
//C/Users/MALHAR/Downloads/pattern-bg_2021-04-09/patternbg.png
function StarList( props ) {
  const elements = [];

  for ( let i = 0; i < props.number; i++ ) {
    elements.push( <img src={star} alt="" className={styles.starstyle} /> );
  }

  return elements;
}
const TestUser1 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Verified User</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <h4>{props.contentHeading}
              </h4>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TestUser2 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Verified User</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <h4>{props.contentHeading}
              </h4>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TestUser3 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Stillwater Customer</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TestUser4 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Stillwater Customer</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TestUser5 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Stillwater Customer</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TestUser6 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Stillwater Customer</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TestUser7 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Stillwater Customer</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TestUser8 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Stillwater Customer</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TestUser9 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Stillwater Customer</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TestUser10 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Stillwater Customer</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const TestUser11 = ( props ) => {
  return (
    <div className="container common-container-space mt-48 px-23">
      <div className='row align-items-center justify-content-center'>
        <div className='col-lg-9 mx-auto '>
          <div className='testimonial_box'>
            <div className='Oval'>
              <div className='Stext'>{props.Shortname}</div>

              <img src={checked1} alt="checked" />
            </div>

            <div>
              <div className='testimonial_box_name'>
                <div> {props.name}

                  <div>Stillwater Customer</div> </div>

                <div className='testimonial_date'>{props.Date}</div>
              </div>

              <div>  <StarList number={props.rating} /></div>

              <h4>{props.contentHeading}
              </h4>

              <div className='testimonial_content'>{props.content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default function testimonial() {
  return (
    <div>
      <Layout header={'menu'}>
        <SEO title='Insurance Reviews and Rating | Customer Testimonials'
          canonicalUrl='https://www.getmybubble.com/testimonials/'
          keywords='bubble insurance reviews, insurance review, insurance rating, insurance testimonials'
          description='Check Out Get My Bubble Reviews and Ratings. Read online insurance testimonials given by our customers regarding insurance policies, plans, quotes and more.'/>

        <div className="wrapper">
          <div className="testimonialwrap">
            <div className="plainBackground flexRow11 testimonial-padding">
              <div className={styles.ContentHeading}>Testimonials</div>

              {/* <div className="testimonial_sub_heading1">Bubble is rated 4.5/5 on average</div>
                <div className="testimonial_sub_heading2">Based on 4,200 reviews</div> */}
            </div>

            {/* <TestUser
                content="Thanks guys, Keep up the Good Work! Bubble should be nominated for best service of the year. You won't regret it."
                name="Elizabeth Martin"
                position="CEO, Abcs"
                rating="5"
                imageUrl={group8} /> */}

            <TestUser1
              Shortname="LS"
              Date="20/08/2021"
              name="Lauren S"
              contentHeading="Best quote and coverage. Happy &#38;
                friendly employees. "
              content="My experience with Bubble was friendly, efficient, &#38; transparent. They were able to match or beat the other quotes I provided in a timely manner. "
              rating="5"/>


            <TestUser2
              Shortname="CS"
              Date="26/08/2021"
              name="Craig S"
              contentHeading="We just bought a house so getting tailor-fit home insurance was a top priority."
              content="I liked that the associate I worked with was extremely knowledgeable and that we could tweak different factors I was looking for on a policy- more so than other brokers I had looked at such as Progressive."
              rating="5" />

            <TestUser3
              Shortname="LS"
              Date="27/02/2021"
              name="Lorrie S"
              content="My water heater broke New Years Day and flooded my garage but also flooded into my
                house. Danielle was the claims rep. Danielle is very professional, honest and caring.
                She is quick to respond and very helpful. In today's world it is hard to find such good
                customer service and caring people. Danielle has been a blessing and beyond
                grateful for her. I would highly recommend Stillwater Insurance."
              rating="5"/>


            <TestUser4
              Shortname="CS"
              Date="26/02/2021"
              name="Carol S"
              content="We were very pleased with the responsive results we had following a significant water
                incident in our home. The representatives that Stillwater sent to assess the damage
                were courteous and helpful, and were responsive as additional damages were needed
                to be assessed."
              rating="5" />

            <TestUser5
              Shortname="TS"
              Date="19/01/2021"
              name="Timothy S"
              content="Stillwater Insurance did a great job in replying to my request, going over all the things
                covered and in a timely manner, they did a great a job!!"
              rating="5" />

            <TestUser6
              Shortname="TL"
              Date="23/09/2020"
              name="Tony L"
              content="Kimberly is awesome! She has helped the whole way and worked on my behalf for a
                resolution."
              rating="5" />

            <TestUser7
              Shortname="LW"
              Date="26/03/2020"
              name="Linda W"
              content="After noticing a water leak on the roof (drywall stains) the adjuster came quickly to
                assess the damage and determined what needed to be done and the payment for the job to be
                done. The communication by phone & email was excellent."
              rating="5" />

            <TestUser8
              Shortname="EW"
              Date="10/03/2020"
              name="Ed W "
              content="Recently had a fire inside the electrical box at our house. Damage was contained within
                the panel box, but with a 3 and 1 year old in the house, it needed to be fixed quickly.
                Stillwater Insurance understood that, and allowed repairs to begin even before they
                inspected the damage. By the time they got out to see the damage, the panel had
                already been replaced. Stillwater came through with a check in short order and
                everything is back to normal."
              rating="5" />

            <TestUser9
              Shortname="PK"
              Date="19/02/2020"
              name="Peyon K"
              content="We recently had a flood and had to move out for five weeks. Yvonne at Stillwater took
                excellent care of us. Found a hotel and had it direct billed to the company. She handled
                all the details concerning damage quickly and in a professional and friendly manner. I
                highly recommend them! You can tell they really care."
              rating="5" />


            <TestUser10
              Shortname="AC"
              Date="22/11/2019"
              name="Annette C"
              content="Very customer focused, pleasant an efficient. I believe this company has a lot of
                integrity and am very satisfied with them."
              rating="5" />


            <TestUser11
              Shortname="MW"
              Date="16/08/2021"
              name="Marvin W"
              contentHeading="This is the whole thing."
              content="Home and life insurance are very important to me. I'm a career trucker who’s always on the move. I can't always be home to take care of my family. Being a new homeowner makes it essential to not only secure my family’s best interests while I'm here but just in case I'm not, I know my family will be OK. Thank you, Bubble Insurance."
              rating="5"/>

          </div>
        </div>
      </Layout>
    </div>

  );
}
//pattern-bg@3x.png
