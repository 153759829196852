// extracted by mini-css-extract-plugin
export var ContentHeading = "testimonial-module--ContentHeading--Vn5mR";
export var descImg = "testimonial-module--descImg--9-Lrw";
export var descImg2 = "testimonial-module--descImg2--8P-ds";
export var description = "testimonial-module--description--pNh6I";
export var description2 = "testimonial-module--description2--8mYlA";
export var designation = "testimonial-module--designation--YBai2";
export var name = "testimonial-module--name--4xUnD";
export var review = "testimonial-module--review--DcZSN";
export var review2 = "testimonial-module--review2--oOezi";
export var starstyle = "testimonial-module--starstyle--P14kq";
export var testimonialPadding = "testimonial-module--testimonial-padding--XXvLM";